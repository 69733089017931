import { MINUTE_IN_MILLIS } from '@dextools/utils';
import { Role } from '../constants/role.constants';

const MINUTES = {
  PREMIUM: 1,
  STANDARD: 2,
  FREE: 10,
  DEFAULT: 10,
} as const;

export class UserUtil {
  /**
   * Sets the refresh time according to account type
   *
   * @param role - Account role
   * @returns Refresh time in milliseconds
   */
  public static getRefreshTimeByAccount(role: Role | null | undefined): number {
    // Add 100ms to defeat call cache
    const delay = 100;

    switch (role) {
      case Role.P100: {
        return MINUTE_IN_MILLIS * MINUTES.PREMIUM + delay;
      }
      case Role.P20: {
        return MINUTE_IN_MILLIS * MINUTES.STANDARD + delay;
      }
      case Role.P0: {
        return MINUTE_IN_MILLIS * MINUTES.FREE + delay;
      }
      default: {
        return MINUTE_IN_MILLIS * MINUTES.DEFAULT + delay;
      }
    }
  }
}
