import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { BaseOutput, User } from '@dextools/core';
import { ApiService, Environment, PathId, AuthenticationService, UserUtil } from '@dextools/core';
import { BehaviorSubject, catchError, distinctUntilChanged, filter, map, of, tap } from 'rxjs';
import type { Observable } from 'rxjs';
import type {
  AuditsData,
  ExternalAuditHolders,
  ApiTokenData,
  ApiTokenListItem,
  ApiTokenPair,
  ApiLiquidityResponse,
  SolanaHolder,
  SolanaHolders,
  ApiTokenWithInfo,
  HoldersOutput,
} from '@dextools/blockchains';
import { Chain, ChainUtil, PairsUtil } from '@dextools/blockchains';
import { MakersApiService } from '../makers/makers-api.service';
import { HttpHeaders } from '@angular/common/http';
import { TokenApiService } from './token-api.service';

const TOKEN_API_VERSION = 1;
const SOLANA_HOLDERS_LIMIT = 20;

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private _userData: User | null = null;
  private _intervalSolanaHolders: ReturnType<typeof setInterval> | null = null;
  private _lastChain: Chain | null = null;
  private _lastTokenAddress = '';
  private readonly _solanaHolders$ = new BehaviorSubject<SolanaHolder | undefined>(undefined);
  private readonly _headers;

  private readonly _liquidity$ = new BehaviorSubject<ApiLiquidityResponse | null>(null);
  private _intervalLiquidity: ReturnType<typeof setInterval> | undefined;

  private readonly _destroyRef = inject(DestroyRef);

  public constructor(
    private readonly _apiService: ApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _environment: Environment,
    private readonly _tokenApiService: TokenApiService,
    private readonly _makersApiService: MakersApiService,
  ) {
    this._headers = {
      ...this._apiService.headers,
      'X-API-Version': TOKEN_API_VERSION,
    };

    this._authenticationService.currentUser$
      .pipe(filter((userData) => JSON.stringify(userData) !== JSON.stringify(this._userData)))
      .subscribe((userData) => {
        // When user connects or disconnects, app is reloaded and hot pairs call it's make automatically.
        // It is not necessary to force it here.
        this._userData = userData;
      });
  }

  public updatePairAndTokenData(
    chain: Chain,
    pairContract: string,
    tokenContract: string,
    txCount: number,
    volume: number,
  ): Observable<HoldersOutput> {
    return this._apiService
      .postUrl<
        BaseOutput<HoldersOutput>
      >(this._environment.paths?.['token']?.update(chain, pairContract.toLowerCase(), tokenContract.toLowerCase(), txCount, volume))
      .pipe(map((result) => result.data));
  }

  public updateAudit(chain: Chain, tokenContract: string, force = false): Observable<AuditsData> {
    return this._apiService
      .postUrl<BaseOutput<AuditsData>>(this._environment.paths?.['token']?.audit(chain, tokenContract.toLowerCase(), force))
      .pipe(map((result) => result.data));
  }

  public solanaHolders$(chain: Chain, address: string, limit: number): Observable<SolanaHolder | undefined> {
    if ((!this._solanaHolders$.value && !this._intervalSolanaHolders) || this._lastChain !== chain || this._lastTokenAddress !== address) {
      this._solanaHolders$.next(undefined);
      this._lastChain = chain;
      this._lastTokenAddress = address;
      this._clearSolanaHolders();
      this._fetchSolanaHolders(chain, address, limit);
      this._intervalSolanaHolders = setInterval(() => {
        if (this._solanaHolders$.observed) {
          this._fetchSolanaHolders(chain, address, limit);
        } else {
          this._clearSolanaHolders();
        }
      }, UserUtil.getRefreshTimeByAccount(this._userData?.plan));
    }

    return this._solanaHolders$.asObservable().pipe(
      filter((value) => value !== undefined && !!value),
      distinctUntilChanged((prev, curr) => JSON.stringify(curr) === JSON.stringify(prev)),
    );
  }

  public getHolders$(
    chain: Chain,
    tokenAddress: string,
    holders: ExternalAuditHolders[],
    price: number,
    limit = SOLANA_HOLDERS_LIMIT,
  ): Observable<ExternalAuditHolders[] | SolanaHolder | undefined> {
    if (chain === Chain.Solana) {
      if (tokenAddress !== '') {
        return this.solanaHolders$(ChainUtil.replaceLegacyChain(chain), tokenAddress, limit).pipe(takeUntilDestroyed(this._destroyRef));
      }
    } else {
      // Check If both needed Data are set, if so show data
      if (holders.length > 0 && price > 0) {
        return of(holders);
      }
    }
    return of([]);
  }

  /**
   * Clear solana holders data and interval.
   */
  private _clearSolanaHolders(): void {
    this._solanaHolders$.next(undefined);
    clearInterval(this._intervalSolanaHolders ?? undefined);
    this._intervalSolanaHolders = null;
  }

  /**
   * Fetch holders data for Solana.
   *
   * @param chain - The chain name.
   * @param address - The token address.
   * @param limit - The limit of holders data.
   */
  private _fetchSolanaHolders(chain: Chain, address: string, limit: number): void {
    this.getSolanaHoldersData(chain, address, limit)
      .pipe(
        tap((solanaHoldersData) => {
          this._solanaHolders$.next(solanaHoldersData);
        }),
      )
      .subscribe();
  }

  /**
   * Get holders data for Solana.
   *
   * @param chain - The chain name.
   * @param address - The token address.
   * @param limit - The limit of holders data.
   *
   * @returns The holders data limited to 20.
   */
  public getSolanaHoldersData(chain: Chain, address: string, limit: number): Observable<SolanaHolder> {
    let url = `/token/holders/${chain}/${address}`;
    if (limit) {
      url += `?limit=${limit}`;
    }
    return this._apiService.get<BaseOutput<SolanaHolders>>(PathId.CORE_API, url, undefined, new HttpHeaders(this._headers)).pipe(
      map((response) => {
        return response.data?.solscan ?? {};
      }),
      catchError((error) => {
        console.error(`Error while fetching known holders Solana for pair ${address}`, error);
        return of({});
      }),
    );
  }

  public getTokenData(chain: Chain, tokenAddress: string): Observable<ApiTokenData | null> {
    return this._tokenApiService.getTokenData(chain, tokenAddress);
  }

  public getLogos(requestedLogos: string[]): Observable<BaseOutput<ApiTokenWithInfo[]>> {
    return this._tokenApiService.getTokenInfo(requestedLogos, ['logo']).pipe(
      map((response) => {
        if (response.data != null) {
          response.data.map((t) => {
            if (t.logo) {
              t.logo = PairsUtil.normalizeLogoUrl(t.logo);
            }

            return t;
          });

          return response;
        }

        return {
          data: [],
        };
      }),
    );
  }

  public getSocialsAndLogos(tokens: string[]): Observable<BaseOutput<ApiTokenWithInfo[]>> {
    return this._tokenApiService.getTokenInfo(tokens, ['logo', 'links']);
  }

  public getTokenList(page: number, pageSize: number, chain?: Chain): Observable<ApiTokenListItem[] | null> {
    return this._tokenApiService.getTokenList(page, pageSize, chain);
  }

  public getTokenPairs(chain: Chain, tokenAddress: string, page: number, pageSize: number): Observable<ApiTokenPair[] | null> {
    return this._tokenApiService.getTokenPairs(chain, tokenAddress, page, pageSize);
  }

  /**
   * Get observable with liquidity data of the given pair.
   *
   * @param chain - Chain
   * @param address - Pair address
   * @returns Observable with liquidity' data
   */
  public getLiquidity$(chain: Chain, address: string): Observable<ApiLiquidityResponse> {
    if ((this._liquidity$.value === null && !this._intervalLiquidity) || this._lastChain !== chain || this._lastTokenAddress !== address) {
      this._lastChain = chain;
      this._lastTokenAddress = address;

      this._clearLiquidity();
      this._fetchLiquidity(chain, address);
      this._intervalLiquidity = setInterval(() => {
        if (this._liquidity$.observed) {
          this._fetchLiquidity(chain, address);
        } else {
          this._clearLiquidity();
        }
      }, UserUtil.getRefreshTimeByAccount(this._userData?.plan));
    }

    return this._liquidity$.asObservable().pipe(
      filter((value): value is NonNullable<ApiLiquidityResponse> => value !== null && !!value.data),
      distinctUntilChanged((prev, curr) => JSON.stringify(curr) === JSON.stringify(prev)),
    );
  }

  /**
   * Get top traders' data.
   *
   * @param chain - Chain
   * @param address - Pair address
   */
  private _fetchLiquidity(chain: Chain, address: string): void {
    this._makersApiService.getLiquidity$(chain, address).subscribe((liquidityData) => {
      this._liquidity$.next(liquidityData);
    });
  }

  /**
   * Clear top traders data and interval.
   */
  private _clearLiquidity(): void {
    this._liquidity$.next(null);
    clearInterval(this._intervalLiquidity);
    this._intervalLiquidity = undefined;
  }
}
