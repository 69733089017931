import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { BaseOutput } from '@dextools/core';
import { ApiService, PathId } from '@dextools/core';
import { catchError, map, Observable, of } from 'rxjs';
import type { ApiLiquidityResponse, MakerBalance, TopTraderResponse, Wallet } from '@dextools/blockchains';
import { Chain, ChainUtil } from '@dextools/blockchains';
import { UrlUtil } from '@dextools/utils';

const MAKERS_API_VERSION = 1;

@Injectable({
  providedIn: 'root',
})
export class MakersApiService {
  private readonly _headers;

  public constructor(private readonly _apiService: ApiService) {
    this._headers = {
      ...this._apiService.headers,
      'X-API-Version': MAKERS_API_VERSION,
    };
  }

  public getMakerDetailedBalance(chain: Chain, pairAddress: string, makerWallet: string): Observable<MakerBalance | null> {
    const legacyChain = ChainUtil.getLegacyChain(chain as Chain);
    const address = UrlUtil.encodeStringAsURIComponent(pairAddress);
    const url = `/maker/summary/${legacyChain}/${address}/${makerWallet}`;

    return this._apiService.get<BaseOutput<MakerBalance>>(PathId.CORE_API, url, undefined, new HttpHeaders(this._headers)).pipe(
      map((response) => ({ ...response.data })),
      catchError((error) => {
        if (error.status === 404) {
          console.error(`There is no detailed summary for maker ${makerWallet} in pair ${pairAddress}`);
        } else {
          console.error(`Error while fetching detailed summary for maker ${makerWallet}`, error);
        }

        return of(null);
      }),
    );
  }

  public getKnownMakers(chain: Chain, pairAddress: string, wallets?: string[]): Observable<Record<string, Wallet>> {
    const legacyChain = ChainUtil.getLegacyChain(chain as Chain);
    const walletsParam = wallets && wallets.length > 0 ? `?wallets=${wallets.join(',')}` : '';
    const address = UrlUtil.encodeStringAsURIComponent(pairAddress);
    const url = `/maker/user/${legacyChain}/${address}${walletsParam}`;

    return this._apiService.get<BaseOutput<Wallet[]>>(PathId.CORE_API, url, undefined, new HttpHeaders(this._headers)).pipe(
      map((response) => {
        // Format response: [Wallet, Wallet, ...] -> { address: Wallet, address: Wallet, ... }
        return response.data.reduce((a, v: Wallet) => ({ ...a, [v.address]: { ...v, chain: ChainUtil.replaceLegacyChain(v.chain) } }), {});
      }),
      catchError((error) => {
        console.error(`Error while fetching known makers for pair ${pairAddress}`, error);
        return of({});
      }),
    );
  }

  public getTopTraders$(chain: Chain, pairAddress: string): Observable<TopTraderResponse> {
    const legacyChain = ChainUtil.getLegacyChain(chain as Chain);
    const address = UrlUtil.encodeStringAsURIComponent(pairAddress);
    const url = `/maker/top/${legacyChain}/${address}`;

    return this._apiService.get<TopTraderResponse>(PathId.CORE_API, url, undefined, new HttpHeaders(this._headers)).pipe(
      catchError((error) => {
        console.error(`Error while fetching known top traders for pair ${pairAddress}`, error);
        return of({
          data: {
            byRealizedProfit: [],
            byTotalProfit: [],
          },
        });
      }),
    );
  }

  public getLiquidity$(chain: Chain, pairAddress: string): Observable<ApiLiquidityResponse> {
    const legacyChain = ChainUtil.getLegacyChain(chain as Chain);
    const address = UrlUtil.encodeStringAsURIComponent(pairAddress);
    const url = `/maker/top/liquidity/providers/${legacyChain}/${address}`;

    return this._apiService.get<ApiLiquidityResponse>(PathId.CORE_API, url, undefined, new HttpHeaders(this._headers)).pipe(
      catchError((error) => {
        console.error(`Error while fetching known liquidity for pair ${pairAddress}`, error);
        return of({
          statusCode: 0,
          data: {
            makers: [],
            total: {
              adds: 0,
              balanceLpToken: 0,
              balanceLpTokenAdded: 0,
              balanceLpTokenBurned: 0,
              balanceLpTokenRemoved: 0,
              balanceToken: 0,
              balanceTokenRef: 0,
              burns: 0,
              removes: 0,
            },
          },
        });
      }),
    );
  }
}
