import type { UserRole } from '../models';

export enum Role {
  P0 = 'P0', // Free
  P20 = 'P20', // Standard
  P100 = 'P100', // Premium
}

export const RoleMapping: Record<Role, UserRole> = {
  P0: 'free',
  P20: 'standard',
  P100: 'premium',
};

export const ALL_ROLES = Object.values(Role);

export const PAID_ROLES = [Role.P100, Role.P20];

export const PAID_USER_ROLES = [RoleMapping[Role.P100], RoleMapping[Role.P20]];
