import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import type { BaseOutput } from '@dextools/core';
import { ApiService, PathId } from '@dextools/core';
import type { ApiTokenData, Chain, ApiTokenWithInfo, ApiTokenListItem, ApiTokenPair } from '@dextools/blockchains';
import { ChainUtil } from '@dextools/blockchains';

@Injectable({
  providedIn: 'root',
})
export class TokenApiService {
  public constructor(private readonly _apiService: ApiService) {}

  /**
   * Call to token detail endpoint.
   *
   * @param chain - Current chain
   * @param tokenAddress - Address of the token we want to get the details of
   *
   * @returns Observable with token data.
   */
  public getTokenData(chain: Chain, tokenAddress: string): Observable<ApiTokenData | null> {
    const finalChain = ChainUtil.getLegacyChain(chain);
    const params = `token=${finalChain}:${tokenAddress}`;

    return this._apiService.get<BaseOutput<ApiTokenData>>(PathId.SHARED, `/data/token/detail?${params}`).pipe(
      map<BaseOutput<ApiTokenData | null>, ApiTokenData | null>((response) => {
        if (response?.data != null) {
          response.data.chain = ChainUtil.replaceLegacyChain(chain as Chain) as Chain;
          return response.data;
        }
        return null;
      }),
    );
  }

  /**
   * Call to token info endpoint.
   *
   * @param requestedLogos - Array with all requested tokens with this format: {chain}:{address}
   * @param fields - Fields we want to obtain from the tokens.
   * At the moment, we are only interested in 'links' and 'logo', but more can be added depending on what we need to be returned by the endpoint.
   *
   * @returns Observable with an array containing id (chain and token address) and all requested fields of tokens.
   */
  public getTokenInfo(requestedLogos: string[], fields: ('logo' | 'links')[]): Observable<BaseOutput<ApiTokenWithInfo[]>> {
    const tokenLogosUrl = `/data/token?tokens=${requestedLogos.filter(Boolean).join(',')}&returnFields=id,${fields.join(',')}`;
    return this._apiService.get<BaseOutput<ApiTokenWithInfo[]>>(PathId.SHARED, tokenLogosUrl);
  }

  /**
   * Call to tokens list endpoint.
   *
   * @param page - Number of the page.
   * @param pageSize - Number of elements per page.
   * @param chain - Current chain.
   *
   * @returns Observable with list of tokens and their data in the requested page.
   */
  public getTokenList(page: number, pageSize: number, chain?: Chain): Observable<ApiTokenListItem[] | null> {
    const chainParam = chain ? `&chain=${ChainUtil.getLegacyChain(chain)}` : '';
    const params = `page=${page}&limit=${pageSize}${chainParam}`;

    return this._apiService.get<BaseOutput<ApiTokenListItem[]>>(PathId.SHARED, `/analytics/tokens?${params}`).pipe(
      map<BaseOutput<ApiTokenListItem[] | null>, ApiTokenListItem[] | null>((response) => {
        if (response?.data != null) {
          return response.data.map((item) => {
            item._id.chain = ChainUtil.replaceLegacyChain(item._id.chain as Chain) as Chain;
            return item;
          });
        }
        return null;
      }),
    );
  }

  /**
   * Call to tokens pairs endpoint.
   *
   * @param chain - Current chain.
   * @param tokenAddress - Address of the token we want to get the pairs of
   * @param page - Number of the page.
   * @param pageSize - Number of elements per page.
   *
   * @returns Observable with list of pairs containing the mentioned token.
   */
  public getTokenPairs(chain: Chain, tokenAddress: string, page: number, pageSize: number): Observable<ApiTokenPair[] | null> {
    const finalChain = ChainUtil.getLegacyChain(chain);
    const params = `token=${finalChain}:${tokenAddress}&page=${page}&limit=${pageSize}`;

    return this._apiService.get<BaseOutput<ApiTokenPair[]>>(PathId.SHARED, `/data/token/pairs?${params}`).pipe(
      map<BaseOutput<ApiTokenPair[] | null>, ApiTokenPair[] | null>((response) => {
        if (response?.data != null) {
          return response.data;
        }
        return null;
      }),
    );
  }
}
